import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'
import styled from 'styled-components';

const StyledImage = styled(Img)`
  &, & span {
    display: inherit;
  }
`;

const Image = () => {
  const data = useStaticQuery(graphql`
    query {
      placeholderImage: file(relativePath: { eq: "sorin-valcan.png" }) {
        childImageSharp {
          fluid(maxWidth: 180) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return <StyledImage
    fluid={data.placeholderImage.childImageSharp.fluid}
    Tag='span'
    style={{
      display: 'inherit',
    }}
  />
}


export default Image
