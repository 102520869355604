import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const StyledButton = styled.button`
  appearance: none;
  background-color: ${ props => props.theme.blue };
  border-radius: 20px;
  font-size: 1.5em;
  padding: 1em 1.5em;
  font-weight: bold;
  line-height: 1.2em;
  width: 100%;
  display: inline-block;
  border: none;
  color: white;
  position: relative;
  overflow: hidden;
  box-shadow: 0 3px 6px 0 rgba(0,0,0,0.2);

  ${ props => props.hasIcon ? `
    padding-left: 5em;
  ` : '' };
`;

const IconWrapper = styled.span`
  display: block;
  width: 2em;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  background: white;
  color: ${ props => props.theme.green };
  font-size: 1.5em;

  &::after {
    display: block;
    content: ' ';
    background: inherit;
    position: absolute;
    right: -0.75em;
    top: 0;
    bottom: 0;
    transform: skew(-22.5deg);
    width: 1.5em;
  }

  svg {
    z-index: 50;
    position: absolute;
    right: 0;
    top: 50%;
    transform: translate(0, -50%);
  }
`;

const wrapIcon = (child, idx) => {
  if (child.type === FontAwesomeIcon) {
    return (
      <IconWrapper key={ idx }>
        { child }
      </IconWrapper>
    );
  }

  return (
    <React.Fragment key={ idx }>
      { child }
    </React.Fragment>
  );
}

const wrapChildrenIfRequired = children => {
  if (!Array.isArray(children)) {
    return children;
  }

  return children.map((child, idx) => wrapIcon(child, idx));
}

const hasIcon = children => {
  if (!Array.isArray(children)) {
    return false;
  }

  return !!children
    .filter(child => child.type === FontAwesomeIcon)
    .length;
}

export const Button = ({ onClick, children }) => {
  return (
    <StyledButton onClick={ onClick } hasIcon={ hasIcon(children) }>
      { wrapChildrenIfRequired(children) }
    </StyledButton>
  )
}


Button.propTypes = {
  onClick: PropTypes.func.isRequired,
};

Button.defaultProps = {
  onClick: () => {},
}

export default Button;
