import React from 'react';
import styled from 'styled-components';
import { useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'

const StyledUl = styled.ul`
  margin: 2em -${ props => props.theme.padding};
  overflow: hidden;

  li {
    display: block;
    padding: 0 ${ props => props.theme.padding};

    a {
      display: block;
      border-radius: 20px;
      overflow: hidden;

      &:hover {
        opacity: 0.8;
      }

      span {
        display: block;
      }
    }

    ${ props => props.theme.media.md`
      width: 33%;
      float: left;
    `}

    + li {
      margin-top: 2em;

      ${ props => props.theme.media.md`
        margin-top: 0;
      `};
    }
  }
`;

const links = {
  'sabineBigler': 'physiotherapie-bigler.at',
  'briefingOne': 'bau.briefing.one',
  'tecfindr': 'tecfindr.com',
}

const renderListItem = (key, data) => {
  return (
    <li key={ key }>
      <a href={ 'https://' + links[key] } target="_blank">
        <Img
          fluid={data.childImageSharp.fluid}
          Tag='span'
          style={{
            display: 'inherit',
          }}
        />
      </a>
    </li>
  )
}

const References = () => {
  const data = useStaticQuery(graphql`
    query {
      sabineBigler: file(relativePath: { eq: "sabine-bigler.png" }) {
        childImageSharp {
          fluid(maxWidth: 400) {
            ...GatsbyImageSharpFluid
          }
        }
      },

      briefingOne: file(relativePath: { eq: "briefing-one.png" }) {
        childImageSharp {
          fluid(maxWidth: 400) {
            ...GatsbyImageSharpFluid
          }
        }
      },

      tecfindr: file(relativePath: { eq: "tecfindr.png" }) {
        childImageSharp {
          fluid(maxWidth: 400) {
            ...GatsbyImageSharpFluid
          }
        }
      },
    }
  `);

  return (
    <StyledUl>
      { Object.keys(data).map(key => renderListItem(key, data[key])) }
    </StyledUl>
  );
}

export default References;
