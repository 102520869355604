import React from 'react';
import styled from 'styled-components';
import Layout from '../components/Layout';
import SEO from '../components/Seo';
import Narrow from '../components/Narrow';
import SorinValcanImage from '../components/Images/SorinValcan';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from '../components/Buttons';
import References from '../components/References';

const StyledSection = styled.section`
  margin: 2em 0;

  h1 {
    font-weight: bold;
  }
`;

const StyledContactSection = styled(StyledSection)`
  text-align: center;
  margin-top: 5em;
`;

const StyledSorinValcan = styled.span`
  display: block;
  float: right;
  width: 120px;
  margin-left: 1em;
  margin-bottom: 1em;

  ${ props => props.theme.media.md`
    width: 180px;
  `}
`;

const StyledContactButtonWrapper = styled.span`
  display: block;
  margin: 2em 0;
  text-align: center;

  > span {
    display: inline-block;
    width: 100%;
    max-width: 400px;
  }

`;

const StyledPhone = styled.span`
  margin: 2em 0;

  &, > span {
    display: block;
  }


  ${ props => props.theme.media.md`
    > span {
      display: inline;
      margin-right: 1em;
    }
  `}

  a {
    font-weight: bold;

    svg {
      margin-right: 0.5em;
      color: ${ props => props.theme.green };
    }
  }
`;

const handleButtonClick = () => {
  window.location.href = 'mailto:office@valcan.at';
}

const IndexPage = () => (
  <Layout>
    <SEO title='Home' />
    <Narrow>
      <StyledSection>
        <h1>Wer bin ich?</h1>
        <p>
          <StyledSorinValcan>
            <SorinValcanImage />
          </StyledSorinValcan>
          Mein Name ist
          Sorin Valcan,
          ich bin ausgebildeter Medienfachmann
          für Mediendesign & Medientechnik.
          Außerdem bin ich seit Kindesalter an Technik interessiert und arbeite seit Jahren als IT-Administrator für Mittelständische Unternehmen.
        </p>
      </StyledSection>
      <StyledSection>
        <h1>Wer die Wahl hat...</h1>
        <p>
          Welches Projekt Sie sich auch vorgenommen haben...
          In Kooperation mit meinen langjährigen Weggefährten möchten wir Ihr Vorhaben kostengünstig und effizient in die Tat umsetzen.
          Denn auch wir wissen, was Sie als Unternehmen am Anfang brauchen!<br/>
          Wir bieten Ihnen <strong>keine</strong> halb(fertig)-lösungen, wie z.B. WordPress, Drupal, Joomla, usw. an - Sie erhalten individuell angepasste Systeme für Ihre Bedürfnisse.
        </p>
      </StyledSection>
      <StyledSection>
        <h1>Web</h1>
        <p>
          Sie brauchen ein neues Design für Ihre Website oder möchten Ihren Online-Auftritt modernisieren?
        </p>
      </StyledSection>
      <StyledSection>
        <h1>Print</h1>
        <p>
          Ob Logos, Visitenkarten, Flyer oder andere Drucksachen, wir unterstützen Sie gerne bei Ihrem Vorhaben.
        </p>
      </StyledSection>
      <StyledSection>
        <h1>IT-Support</h1>
        <p>
          Sie brauchen Hilfe, Unterstützung oder Beratung zu Ihrer aktuellen EDV-Infrastruktur?<br/>
          Gerne helfe ich Ihnen zu Themen wie: Server, lokale oder gehostete Cloud-Lösungen, NAS, Virtuelle Maschinen, ...<br/>
          Schnelle und günstige Lösungen haben bereits diverse Einzelunternehmen und KMUs überzeugt.
        </p>
      </StyledSection>
      <StyledSection>
        <h1>Referenzen</h1>
        <References />
      </StyledSection>
      <StyledContactSection>
        <h1>Sie haben Fragen, oder möchten einen Termin vereinbaren?</h1>
        <p>
          <StyledContactButtonWrapper>
            <span>
              <Button onClick={ handleButtonClick }>
                <FontAwesomeIcon icon={ ['fas', 'comments'] } transform='flip-h' />
                <span>Frage stellen ...</span>
              </Button>
            </span>
          </StyledContactButtonWrapper>

          <StyledPhone>
            <span>
              Natürlich können Sie mich auch gerne telefonisch erreichen:
            </span>

            <a href='tel:+43 681 202 800 27'>
              <FontAwesomeIcon icon={ ['fas', 'phone'] } transform='flip-h' />
              +43 681 202 800 27
            </a>
          </StyledPhone>
        </p>
      </StyledContactSection>
    </Narrow>
  </Layout>
)

export default IndexPage
